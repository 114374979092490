.grid {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
  height: calc(100vh - 70px);
  grid-template-areas: 'image bio';
  width: 100%;
  position: relative;
}

.image {
  composes: bgWhiteTexture from global;
  grid-area: image;
  position: relative;
  overflow: hidden;
}

.image::before {
  content: ' ';
  position: absolute;
  z-index: 1;
  border: 1px solid var(--eb-blue);
  border-right: none;
  top: 2vw;
  bottom: 2vw;
  left: 2vw;
  right: 0;
}

.image::after {
  content: ' ';
  position: absolute;
  z-index: 1;
  border: 1px solid var(--eb-blue);
  border-right: none;
  top: 1vw;
  bottom: 1vw;
  left: 3vw;
  right: 0;
}

.img {
  object-fit: cover;
  position: absolute;
  bottom: 2vw;
  right: 0;
  width: calc(100% - 3vw);
  max-height: calc(100% - 4vw);
}

.bio {
  composes: flex flex-column items-start justify-between from global;
  grid-area: bio;
  background-color: var(--eb-blue);
  padding: 15% 4vw 25% 3vw;
  height: 100%;
  position: relative;
  box-shadow: 0 0 10px var(--eb-blue-dark);
}

.bioMobile {
  composes: flex flex-column items-start justify-between from global;
  background-color: var(--eb-blue);
  padding: 3vh 5vw;
  height: calc(100vh - 140px);
  position: relative;
}

.bioMobile::before {
  content: '';
  position: absolute;
  top: 3vw;
  bottom: 3vw;
  left: 0;
  right: 0;
  border: 1px solid var(--eb-white);
}

.bioMobile::after {
  content: '';
  position: absolute;
  top: 1vw;
  bottom: 1vw;
  left: 3vw;
  right: 3vw;
  border: 1px solid var(--eb-white);
}

.bioMobile .paragraph {
  font-size: 14px;
}

.bioMobile .heading {
  font-size: 16px;
}

.bio::before {
  content: ' ';
  position: absolute;
  z-index: 1;
  border: 1px solid var(--eb-white);
  border-left: none;
  top: 2vw;
  bottom: 2vw;
  left: 0;
  right: 2vw;
}

.bio::after {
  content: ' ';
  position: absolute;
  z-index: 1;
  border: 1px solid var(--eb-white);
  border-left: none;
  top: 1vw;
  bottom: 1vw;
  right: 3vw;
  left: 0;
}

.paragraph {
  color: var(--eb-white);
  font-weight: 500;
  line-height: 1.5;
  font-size: 18px;
  font-style: italic;
  letter-spacing: 0.4px;
  z-index: 100;
}

.heading {
  composes: nunito from global;
  font-size: 18px;
  color: var(--eb-white);
  line-height: 1.5;
}

.link {
  text-decoration: none;
  font-weight: bold;
  color: var(--eb-sage);
}
