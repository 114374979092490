.outer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative;
  width: 100%;
  height: 100%;
}

.mobileOuter {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
  width: 100%;
  height: 100%;
}

.col {
  composes: flex flex-column justify-start items-center from global;
  width: 100%;
  height: 100%;
}
