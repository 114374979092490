.contact {
  position: fixed;
  z-index: 100000;
  right: 20px;
  bottom: 20px;
  left: auto;
  top: auto;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
}

.icon {
  clip-path: circle(50% at 50% 50%);
  cursor: pointer;
  width: 50px;
  height: 50px;
  transition: all 0.2s ease-in-out;
}

.icon > circle {
  fill: var(--eb-green);
}

.icon > path {
  fill: var(--eb-white);
}

.icon.hovered {
  transform: scale(1.1);
}

.tooltip {
  composes: lato flex items-center justify-center bgDarkGreen textWhite from global;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 5px;
  width: 80px;
  font-size: 12px;
  position: absolute;
  bottom: calc(100% + 10px);
  right: -15px;
}

.tooltip:after {
  content: ' ';
  position: absolute;
  top: 95%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--eb-green) transparent transparent transparent;
}
