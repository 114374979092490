.small {
  font-size: 14px;
  padding: 0 10px;
}

.medium {
  font-size: 16px;
  padding: 5px 20px;
}

.large {
  font-size: 18px;
  padding: 10px 25px;
}

.button {
  composes: nunito from global;
  color: #fff;
  transition: all 0.3s;
  position: relative;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.button span {
  transition: all 0.3s;
  line-height: 2;
  text-transform: uppercase;
  color: var(--eb-white);
}
.button::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: var(--eb-white);
  border-bottom-color: var(--eb-white);
  transform: scale(0.1, 1);
}
.button:hover span {
  letter-spacing: 2px;
}
.button:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.05);
}
.button:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}
