.firebaseAuth {
  width: 100%;
  flex-grow: 2;
  padding: 20% 0;
}

.firebaseAuth > div > div > form > ul {
  list-style: none;
  padding: 0;
}

.firebaseAuth > div > div > form > ul > li {
  width: 100%;
}

.firebaseAuth > div > div > form > ul > li > button {
  width: 100%;
  padding: 0;
  outline: none;
  border: 2px solid var(--eb-white);
  background-color: var(--eb-blue) !important;
  height: 140px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.firebaseAuth > div > div > form > ul > li > button:hover {
  box-shadow: inset 0px 0px 25px 0px rgba(255, 255, 255, 0.1),
    0px 2px 10px -2px rgba(255, 255, 255, 0.5);
}

.firebaseAuth > div > div > form > ul > li > button:hover::after {
  border: 2px solid var(--eb-white);
  width: calc(100% - 10px);
  height: calc(100% + 10px);
}

.firebaseAuth > div > div > form > ul > li > button > span:first-child {
  height: 105px;
  background-color: var(--eb-white);
  border-radius: 50%;
  padding: 10px;
}

.firebaseAuth > div > div > form > ul > li > button img {
  height: 100%;
}

.firebaseAuth > div > div > form > ul > li > button > span:nth-child(2) {
  color: var(--eb-white);
  font-weight: bold;
  font-size: 18px;
  margin-left: 20px;
  font-family: 'Lato', sans-serif;
}

.firebaseAuth > div > div > form > ul > li > button > span:last-child {
  display: none;
}

.authActions {
  composes: flex flex-column items-center justify-center from global;
  height: 62px;
  width: 100%;
}

.authAction {
  margin: 10px 0;
  width: 80%;
}

.authMessages {
  composes: flex flex-column items-center justify-center lato from global;
  width: 100%;
  height: 140px;
  font-size: 18px;
  font-weight: bold;
  color: var(--eb-white);
  border-bottom: 2px solid var(--eb-white);
  transition: all 0.2s ease-in-out;
  padding: 25% 0;
}

.authMessages.error {
  color: var(--eb-tc);
  border-bottom: 2px solid var(--eb-tc);
}
