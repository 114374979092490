.textAreaContainer {
  composes: flex flex-column items-start justify-between from global;
  margin: 7px 0;
  min-height: 105px;
  width: 100%;
}

.textArea {
  composes: lato from global;
  font-size: 14px;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  max-height: 210px;
  color: var(--eb-green);
  outline: none;
  padding: 7px;
  border: 1px solid var(--eb-green);
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.textArea:hover,
.textArea:focus {
  box-shadow: 0 0 5px -2px var(--eb-green);
}
