.page {
  composes: flex flex-column items-center justify-start bgWhiteTexture from global;
  position: absolute;
  top: 0;
  bottom: auto;
  right: 0;
  left: 0;
}

.content {
  composes: flex flex-column items-center justify-start from global;
  height: 100vh;
  width: 100vw;
  padding-top: 70px;
  position: relative;
  overflow-y: scroll;
}
