.flyout {
  composes: flex flex-column items-center justify-start from global;
  animation: slideLeftClose 0.2s ease-in-out forwards;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  width: 400px;
  z-index: 1000000;
  min-height: -webkit-fill-available;
  background: linear-gradient(
    135deg,
    var(--eb-blue) 0%,
    var(--eb-blue) 30%,
    var(--eb-blue-dark) 100%
  );
  box-shadow: 10px 10px 15px 0px var(--eb-blue);
}

.flyout.open {
  animation: slideLeftOpen 0.5s ease-in-out forwards;
  left: 0;
}

flyout.animateClose {
  animation: slideLeftClose 0.2s ease-in-out forwards;
}

.flyout.mobile {
  width: 90%;
}

.flyoutHeader {
  composes: flex justify-center elevation-4 from global;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  color: var(--eb-white);
  z-index: 1000;
}

.nunito {
  composes: nunito from global;
  font-size: 28px;
  padding: 18px 15% 0;
  font-weight: bold;
}

.canon {
  composes: canon from global;
  font-size: 36px;
  padding-top: 18px;
}

.closeIcon {
  composes: flex items-center justify-center from global;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  height: 70px;
  width: 70px;
  padding: 18px 14px;
  cursor: pointer;
}

.closeIcon path {
  fill: var(--eb-white);
}

.flyoutContent {
  composes: flex flex-column items-center justify-start from global;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 112px 28px 42px;
  z-index: 100;
}

@keyframes slideLeftOpen {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@keyframes slideLeftClose {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
