.carousel {
  position: sticky;
  top: 0;
  min-height: 40vh;
}

.testimonial {
  composes: flex flex-column items-start justify-between from global;
  height: 36vh;
  padding: 2vh 2vw;
  margin: 2vh 4vw;
  line-height: 1.5;
  color: var(--eb-blue-dark);
  border: 1px solid var(--eb-blue-dark);
  font-size: 18px;
  position: relative;
  font-style: italic;
}

.testimonial::before {
  position: absolute;
  content: '';
  top: 5px;
  bottom: 5px;
  left: -10px;
  right: -10px;
  border: inherit;
}

.testimonial.mobile {
  height: 38vh;
  margin: 5px 10px;
  padding: 10px 10px;
  font-size: 14px;
  line-height: 1.1;
}

.testimonial.mobile strong {
  font-size: 16px;
}

.testimonial.mobile .author {
  font-size: 16px;
}

.paragraph {
  text-align: left;
  letter-spacing: 0.4px;
}

.author {
  composes: nunito from global;
  align-self: flex-end;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
}

.author span {
  font-size: 16px;
}

.strong {
  composes: nunito from global;
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
}
