.inputContainer {
  composes: flex flex-column items-start justify-between from global;
  margin: 7px 0;
  height: 42px;
  width: 100%;
}

.input {
  composes: lato from global;
  font-size: 14px;
  width: 100%;
  margin: 7px 0;
  color: var(--eb-green);
  outline: none;
  padding: 7px;
  border: 1px solid var(--eb-green);
  background-color: #fff;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.input:hover,
.input:focus {
  box-shadow: 0 0 5px -2px var(--eb-green);
}
