.header {
  composes: flex items-baseline justify-evenly bgWhite elevation-0 from global;
  animation: slideTopClose 0.5s ease-in-out forwards;
  z-index: 1000;
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  padding: 18px 0 10px;
  height: 70px;
}

.header.animateClose {
  animation: slideTopClose 0.5s ease-in-out forwards;
}

.header.open {
  animation: slideTopOpen 0.5s ease-in-out forwards;
  top: 0;
}

@keyframes slideTopOpen {
  0% {
    top: -70px;
  }
  100% {
    top: 0;
  }
}

@keyframes slideTopClose {
  0% {
    top: 0;
  }
  100% {
    top: -70px;
  }
}

.nav {
  composes: canon flex items-center justify-center textBlue from global;
  font-size: 20px;
  opacity: 0.9;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 18%;
}

.nav.needsSmallerFont:not(.large) {
  font-size: 18px;
}

.nav.needsEvenSmallerFont:not(.large) {
  font-size: 16px;
}

.nav:hover {
  opacity: 0.95;
}

.nav > span {
  cursor: pointer;
}

.nav > span:hover {
  transform: scale(1.01);
}

.large {
  font-size: 36px;
  width: 28%;
}

.active {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.active > span {
  transform: scale(1.01);
}

.nav:not(.large)::after {
  content: ' ';
  position: absolute;
  top: calc(100% + 13px);
  left: 50%;
  right: 50%;
  background-color: var(--eb-blue);
  height: 2px;
  border-radius: 2px;
  transition: all 0.2s ease-out;
}

.active:not(.large)::after {
  content: ' ';
  position: absolute;
  top: calc(100% + 13px);
  left: 15%;
  right: 15%;
  background-color: var(--eb-blue);
  height: 2px;
  border-radius: 2px;
}
