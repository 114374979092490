.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr 40px 1fr;
  grid-template-areas:
    'instagram-link'
    'instagram'
    'pinterest-link'
    'pinterest';
  height: calc(100vh - 140px);
  width: 100%;
  padding: 2%;
}
