a {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
}

.pinterestLinkContainer {
  composes: flex items-center from global;
  justify-content: flex-end;
  grid-area: pinterest-link;
  font-weight: bold;
}

.pinterestLink {
  composes: flex items-center from global;
  cursor: pointer;
  padding: 5px 10px;
  position: relative;
  border-radius: 4px;
  background: #d42d2a;
}

.profileName {
  line-height: 20px;
  margin-left: 10px;
  font-size: 16px;
  color: #f9f9f9;
}

.icon {
  color: #f9f9f9;
  font-size: 20px;
}

.pinterestFeed {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-area: pinterest;
  overflow-x: scroll;
  width: 100%;
}
