.inputContainer {
  composes: flex flex-column items-start justify-start from global;
  width: 45%;
  height: 63px;
}

.label {
  composes: lato from global;
  font-size: 14px;
  margin-bottom: 7px;
  color: var(--eb-green);
  font-weight: bold;
}

.label::after {
  content: ':';
}

.shared {
  padding: 7px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  width: 100%;
  height: 35px;
}

.shared:hover {
  box-shadow: 0 0 5px -2px var(--eb-green);
}

.button {
  composes: lato flex items-center justify-center from global;
  composes: shared;
  border: 1px solid var(--eb-green);
  background-color: var(--eb-green);
  color: #fff;
}

.fileName {
  composes: shared;
  padding: 7px;
  border: 1px solid var(--eb-green);
  background-color: #fff;
  color: var(--eb-green);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type='file'] {
  display: none;
}
