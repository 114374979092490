.frame {
  composes: flex flex-column items-stretch justify-start from global;
  width: 100%;
  position: relative;
  background-color: var(--eb-blue);
}

@media only screen and (max-width: 1048px) {
  .frame:last-of-type {
    margin-bottom: 70px;
  }
}

.imageContainer {
  width: 100%;
  position: relative;
}

.imageContainer.sixteenNine {
  padding-bottom: 56.25%;
}

.imageContainer.fourThree {
  padding-bottom: 75%;
}

.imageContainer.oneOne {
  padding-bottom: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5) inset;
}

.innerBorder {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  border: 1px solid var(--eb-white);
}

.caption {
  composes: flex items-center justify-center nunito from global;
  padding: 25px 0 35px;
  color: var(--eb-white);
  height: 50px;
}

.descriptionOverlay {
  composes: flex flex-column items-center justify-center from global;
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 0;
  z-index: 99;
  background-color: transparent;
  backdrop-filter: blur(0);
  transition: all 0.4s ease-out;
}

.descriptionOverlay.visible {
  backdrop-filter: blur(4px);
  background-color: rgba(21, 30, 40, 0.5);
}

.nothing {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(21, 30, 40, 0.8) 70%);
}

.description {
  composes: lato from global;
  width: 100%;
  font-size: 16px;
  font-style: italic;
  line-height: 1.75;
  font-weight: bold;
  padding: 10px 15px;
  color: var(--eb-white);
  z-index: 100;
  opacity: 0;
  transition: all 0.2s ease-out;
  text-align: center;
}

.description.mobile {
  line-height: 1.5;
  font-size: 14px;
}

.description.visible {
  opacity: 1;
  animation-delay: 0s;
}
