.projectForm {
  composes: flex flex-column items-start justify-start from global;
  width: 80%;
}

.projectPreview {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 500px;
  margin: 14px 0;
}

.col {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.projectPreviewTitle {
  composes: canon from global;
  margin-top: 14px;
  font-size: 36px;
  color: var(--eb-blue);
}

.blankProject {
  composes: lato flex items-center justify-center from global;
  font-size: 24px;
  font-weight: bold;
  color: var(--eb-blue);
  text-align: center;
  padding: 105px 0;
}

.selectors {
  composes: flex justify-between from global;
  width: 100%;
  margin: 7px 0;
}

.pageContent {
  display: grid;
  height: 100vh;
  padding: 0 42px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.footerButton {
  margin: 0 35px;
  width: 200px;
}
