@import url('https://fonts.googleapis.com/css2?family=IM+Fell+French+Canon+SC&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arapey:ital@0;1&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

:root {
  --eb-white: #f2f7f5;
  --eb-green: #122b1e;
  --eb-green-light: #183928;
  --eb-green-dark: #0c1d14;
  --eb-sage: #afbe8f;
  --eb-tc: #e37463;
  --eb-blue: #151e28;
  --eb-blue-light: #1e2b39;
  --eb-blue-dark: #0c1117;
  --pad: 40px;
  --space: 0px;
  --stroke: 4px;
}

.canon {
  font-family: 'IM Fell French Canon SC', serif;
}

.lato {
  font-family: 'Lato', sans-serif;
}

.nunito {
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.arapey {
  font-family: 'Arapey', serif;
  font-weight: bold;
  font-style: italic;
}

.assistant {
  font-family: 'Assistant', sans-serif;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.bgWhite {
  background-color: var(--eb-white);
}

.bgWhiteTexture {
  background-color: var(--eb-white);
  background-image: url('../assets/textile.png');
  background-repeat: repeat;
  background-size: 10%;
}

.bgSageTexture {
  background-color: var(--eb-sage);
  background-image: url('../assets/textile.png');
  background-repeat: repeat;
  background-size: 10%;
}

.bgWhitePaper {
  background-color: var(--eb-white);
  background-image: url('../assets/paper.png');
  background-repeat: repeat;
  background-size: 20%;
}

.bgBluePaper {
  background-color: var(--eb-blue);
  background-image: url('../assets/paper.png');
  background-repeat: repeat;
  background-size: 5%;
}

.bgWhiteCityscape {
  background-image: url('../assets/cityscape.png');
}

.bgDarkGreen {
  background-color: var(--eb-green);
}

.bgLightGreen {
  background-color: var(--eb-sage);
}

.bgTerraCotta {
  background-color: var(--eb-tc);
}

.bgBlue {
  background-color: var(--eb-blue);
}

.textWhite {
  color: var(--eb-white);
}

.textDarkGreen {
  color: var(--eb-green);
}

.textLightGreen {
  color: var(--eb-sage);
}

.textTerraCotta {
  color: var(--eb-tc);
}

.textBlue {
  color: var(--eb-blue);
}

.elevation-0 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.14);
}

.elevation-0-inv {
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.14);
}

.elevation-1 {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
}

.elevation-2 {
  box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.12);
}

.elevation-2-inv {
  box-shadow: 0px -8px 12px -2px rgba(0, 0, 0, 0.12);
}

.elevation-3 {
  box-shadow: 0px 9px 20px -2px rgba(0, 0, 0, 0.15);
}

.elevation-4 {
  box-shadow: 0px 10px 28px -10px rgba(0, 0, 0, 0.5);
}

.frame-outer {
  box-sizing: content-box;
  width: 400px;
  background: var(--eb-blue);
  margin: 48px auto;
  padding: 16px;
  position: relative;
  overflow: hidden;
}

.frame-outer > img {
  box-sizing: content-box;
  width: 400px;
  height: auto;
  object-fit: cover;
  opacity: 0.5;
}

.frame-outer::after {
  box-sizing: content-box;
  content: ' ';
  display: block;
  position: absolute;
  top: var(--pad);
  right: var(--pad);
  left: var(--pad);
  bottom: var(--pad) - var(--space) + 7px;
  border: solid var(--stroke) var(--eb-white);
  border-bottom: 0;
}

.frame-inner {
  box-sizing: content-box;
  color: var(--eb-white);
  position: absolute;
  text-align: center;
  bottom: var(--pad) - var(--space);
  left: var(--pad);
  right: var(--pad);
  white-space: nowrap;
  overflow: hidden;
  font-family: 'IM Fell French Canon SC', serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.frame-inner > div {
  box-sizing: content-box;
  width: 200px;
  margin-left: calc(-1 * calc(2000px - calc(400px - var(--pad))) / 2);
}

.inner-text::before,
.inner-text::after {
  box-sizing: content-box;
  content: '';
  display: inline-block;
  width: 600px;
  height: var(--stroke);
  background: var(--eb-white);
  vertical-align: middle;
}

.inner-text::before {
  box-sizing: content-box;
  margin-right: 16px;
}

.inner-text::after {
  box-sizing: content-box;
  margin-left: 16px;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
  min-height: -webkit-fill-available;
}
