.userInfo {
  composes: flex items-center justify-start from global;
  max-height: 140px;
  width: 100%;
  padding: 25% 14px;
  border-bottom: 1px solid var(--eb-white);
}

.avatar {
  height: 105px;
  width: auto;
  border-radius: 50%;
  border: 2px solid var(--eb-white);
}

.displayName {
  composes: nunito from global;
  line-height: 70px;
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
  color: var(--eb-white);
}

.contentSelector {
  composes: flex flex-column items-start justify-start from global;
  position: relative;
  width: 100%;
  padding: 20% 0;
  font-weight: bold;
  flex-grow: 2;
}

.contentOption {
  width: 100%;
  composes: nunito from global;
  position: relative;
  font-weight: bold;
  font-size: 18px;
  color: var(--eb-sage);
  cursor: pointer;
  margin: 15px 0;
  padding-left: 15px;
  transition: all 0.2s ease-out;
  line-height: 30px;
}

.contentOption:not(.active):hover {
  color: var(--eb-white);
  opacity: 0.8;
  font-size: 19px;
}

.contentOption::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 15px;
  right: auto;
  height: 1px;
  width: 0px;
  background-color: var(--eb-sage);
  transition: all 0.2s ease-out;
}

.contentOption.active {
  font-size: 20px;
  color: var(--eb-white);
}

.contentOption.active::after {
  width: 60px;
  background-color: var(--eb-white);
}

.authActions {
  composes: flex flex-column items-center justify-center from global;
  height: 104px;
  width: 100%;
}

.authAction {
  margin: 10px 0;
  width: 80%;
}
