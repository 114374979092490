.header {
  composes: flex justify-center bgWhite elevation-0 from global;
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  padding: 18px 0 0;
  height: 70px;
}

.hamburgerMenu {
  position: absolute;
  left: 28px;
  bottom: 14px;
  height: 35px;
  width: 35px;
}

.headerText {
  composes: canon from global;
  font-size: 36px;
}

.navLinks {
  composes: flex flex-column nunito from global;
  font-weight: bold;
  width: 100%;
  height: 80%;
}

.navLink {
  position: relative;
  font-size: 20px;
  color: var(--eb-sage);
  margin: 14px 0;
  line-height: 2;
  transition: all 0.2s ease-out;
  text-transform: uppercase;
}

.navLink.active {
  opacity: 1;
  font-size: 22px;
  color: var(--eb-white);
}

.navLink::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  right: auto;
  height: 1px;
  width: 0px;
  background-color: var(--eb-sage);
  transition: all 0.2s ease-out;
}

.navLink.active::after {
  width: 120px;
  background-color: var(--eb-white);
}

.siteFooter {
  composes: flex flex-column items-center justify-center from global;
  color: var(--eb-white);
}

.copyright {
  composes: nunito from global;
  font-weight: bold;
}

.adminPortal {
  cursor: pointer;
  font-weight: bold;
  color: var(--eb-blue);
}

a {
  cursor: pointer !important;
  text-decoration: none;
  font-weight: bold;
}

.footer.closed {
  height: 0px;
  padding: 0;
}
