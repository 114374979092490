a {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
}

.instagramLinkContainer {
  composes: flex items-center justify-start from global;
  grid-area: instagram-link;
  font-weight: bold;
}

.instagramLink {
  composes: flex items-center justify-start from global;
  cursor: pointer;
  padding: 5px 10px;
  position: relative;
  border-radius: 4px;
  background: linear-gradient(100deg, #f77737 0%, #e1306c 40%, #e1306c 60%, #5851db 100%);
}

.profileName {
  line-height: 20px;
  margin-left: 10px;
  font-size: 16px;
  color: #f9f9f9;
}

.icon {
  color: #f9f9f9;
  font-size: 20px;
}

.instagramFeed {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-area: instagram;
  overflow-x: scroll;
  width: 100%;
}
