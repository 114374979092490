.footer {
  composes: flex flex-column items-center justify-start bgLightGreen textDarkGreen elevation-2-inv from global;
  padding: 10px 20px 18px;
  height: 100px;
  width: 100%;
  transition: all 0.5s ease-in-out;
  font-size: 14px;
}

.footer > span {
  margin-bottom: 10px;
}

.copyright {
  composes: nunito from global;
  font-weight: bold;
}

.link {
  cursor: pointer;
  font-weight: bold;
  color: var(--eb-blue);
  margin: 0 10px;
}

a {
  cursor: pointer !important;
  text-decoration: none;
  font-weight: bold;
}

.footer.closed {
  height: 0px;
  padding: 0;
}
