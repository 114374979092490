.post {
  composes: flex items-center justify-center from global;
  cursor: pointer;
  margin: 0 20px;
  border: 1px solid #d6d6d6;
  height: calc(50vh - 150px);
  padding: 10px;
  border-radius: 2px;
  background-color: #f9f9f9;
}

.post:first-child {
  margin-left: 0;
}

.post:last-child {
  margin-right: 0;
}

.image {
  object-fit: contain;
  max-height: calc(50vh - 170px);
  min-height: calc(50vh - 170px);
}
