.modalOverlay {
  composes: flex items-center justify-center from global;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  background-color: rgba(255, 255, 255, 0.4);
}

.modal {
  composes: flex flex-column items-center justify-center from global;
  z-index: 10000;
  position: relative;
  box-shadow: 0 0 50px 10px var(--eb-blue);
  width: 50vw;
}

.modal::before {
  background-color: var(--eb-blue);
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
  content: ' ';
  z-index: 9999;
  border: 1px solid var(--eb-white);
}

.modal::after {
  background-color: var(--eb-blue);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  right: -10px;
  content: ' ';
  z-index: 9999;
  border: 1px solid var(--eb-white);
}

.form {
  composes: flex flex-column items-center justify-between from global;
  overflow: hidden;
  width: 100%;
  color: var(--eb-white);
  background-color: var(--eb-blue);
  border: 1px solid var(--eb-white);
  z-index: 10001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
}

.formContent {
  composes: flex flex-column items-stretch justify-between from global;
  padding: 20px;
  width: 100%;
}

.formFooter {
  composes: flex items-center from global;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  padding: 15px 30px;
}

.title {
  composes: flex items-center from global;
  width: 100%;
  justify-content: flex-start;
  composes: nunito from global;
  padding: 20px 20px 0;
  color: var(--eb-white);
  font-weight: bold;
  font-size: 18px;
}

.feedback {
  composes: flex items-center justify-start from global;
  padding: 10px 20px 0;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  min-height: 30px;
  width: 100%;
}

.error {
  color: var(--eb-tc);
}

.success {
  color: var(--eb-white);
}

.input {
  composes: lato from global;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.75);
  padding: 5px 0 5px 10px;
  flex: 0 0 49%;
  line-height: 2;
  outline: none;
  color: var(--eb-blue);
  font-size: 16px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.2s ease-in-out, border 0.1s ease-in-out;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.input:hover {
  border-color: var(--eb-white);
}

.input:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
  border-color: var(--eb-white);
}

.row {
  composes: flex items-center justify-between from global;
  margin-bottom: 20px;
}
