.tray {
  composes: flex flex-column items-center justify-start elevation-0 from global;
  animation: slideDownClose 0.3s ease-out forwards;
  overflow: hidden;
  position: fixed;
  bottom: calc(70px - 100%);
  top: auto;
  left: 0;
  right: 0;
  width: 100vw;
  height: calc(100vh - 70px);
  z-index: 1000000;
  min-height: -webkit-fill-available;
  background: linear-gradient(
    135deg,
    var(--eb-blue) 0%,
    var(--eb-blue) 30%,
    var(--eb-blue-dark) 100%
  );
}

.tray.open {
  animation: slideDownOpen 0.3s ease-out forwards;
  bottom: 0;
  top: 70px;
}

tray.animateClose {
  animation: slideDownClose 0.3s ease-out forwards;
}

.bar {
  composes: flex justify-center items-center from global;
  width: 100%;
  height: 70px;
  background-color: var(--eb-white);
  z-index: 1000;
  transition: all 0.3s ease-out;
}

.bar.open {
  height: 50px;
}

.chevron {
  composes: flex items-center justify-center from global;
  position: absolute;
  height: 35px;
  width: 35px;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease-out;
  transform: rotate(180deg);
}

.chevron path {
  fill: var(--eb-blue);
}

.chevron.open {
  transform: rotate(0deg);
}

.content {
  composes: flex flex-column items-center justify-start from global;
  width: 100%;
  padding: 0 28px;
  flex-grow: 1;
}

@keyframes slideDownOpen {
  0% {
    bottom: calc(70px - 100%);
    top: auto;
  }
  100% {
    bottom: 0;
    top: 70px;
  }
}

@keyframes slideDownClose {
  0% {
    bottom: 0;
    top: 70px;
  }
  100% {
    bottom: calc(70px - 100%);
    top: auto;
  }
}
