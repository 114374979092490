.selectContainer {
  composes: flex flex-column items-start justify-start from global;
  height: 63px;
  width: 45%;
}

.label {
  composes: lato from global;
  font-size: 14px;
  margin-bottom: 7px;
  color: var(--eb-green);
  font-weight: bold;
}

.label::after {
  content: ':';
}

.select {
  composes: lato from global;
  font-size: 14px;
  outline: none;
  padding: 7px;
  width: 100%;
  border: 1px solid var(--eb-green);
  background-color: #fff;
  border-radius: 4px;
  color: var(--eb-green);
  font-weight: bold;
  cursor: pointer;
  height: 35px;
}

.select:hover {
  box-shadow: 0 0 5px -2px var(--eb-green);
}
