.page {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.main {
  composes: flex justify-center items-center bgWhiteTexture from global;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  padding-left: 400px;
}

.contentContainer {
  composes: flex flex-column items-center justify-start from global;
  position: relative;
  width: 100%;
  height: 100%;
}
