@import url(https://fonts.googleapis.com/css2?family=IM+Fell+French+Canon+SC&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Arapey:ital@0;1&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@400;500;600;700&display=swap);
.styles_header__3Uhrh {
  animation: styles_slideTopClose__3y-ku 0.5s ease-in-out forwards;
  z-index: 1000;
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  padding: 18px 0 10px;
  height: 70px;
}

.styles_header__3Uhrh.styles_animateClose__3aBVV {
  animation: styles_slideTopClose__3y-ku 0.5s ease-in-out forwards;
}

.styles_header__3Uhrh.styles_open__3F25C {
  animation: styles_slideTopOpen__3Y8fF 0.5s ease-in-out forwards;
  top: 0;
}

@keyframes styles_slideTopOpen__3Y8fF {
  0% {
    top: -70px;
  }
  100% {
    top: 0;
  }
}

@keyframes styles_slideTopClose__3y-ku {
  0% {
    top: 0;
  }
  100% {
    top: -70px;
  }
}

.styles_nav__1pJjr {
  font-size: 20px;
  opacity: 0.9;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 18%;
}

.styles_nav__1pJjr.styles_needsSmallerFont__18WBg:not(.styles_large__3TvYH) {
  font-size: 18px;
}

.styles_nav__1pJjr.styles_needsEvenSmallerFont__3yeKU:not(.styles_large__3TvYH) {
  font-size: 16px;
}

.styles_nav__1pJjr:hover {
  opacity: 0.95;
}

.styles_nav__1pJjr > span {
  cursor: pointer;
}

.styles_nav__1pJjr > span:hover {
  transform: scale(1.01);
}

.styles_large__3TvYH {
  font-size: 36px;
  width: 28%;
}

.styles_active__dU2jz {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.styles_active__dU2jz > span {
  transform: scale(1.01);
}

.styles_nav__1pJjr:not(.styles_large__3TvYH)::after {
  content: ' ';
  position: absolute;
  top: calc(100% + 13px);
  left: 50%;
  right: 50%;
  background-color: var(--eb-blue);
  height: 2px;
  border-radius: 2px;
  transition: all 0.2s ease-out;
}

.styles_active__dU2jz:not(.styles_large__3TvYH)::after {
  content: ' ';
  position: absolute;
  top: calc(100% + 13px);
  left: 15%;
  right: 15%;
  background-color: var(--eb-blue);
  height: 2px;
  border-radius: 2px;
}

.styles_tray__1YGiT {
  animation: styles_slideDownClose__3JzHe 0.3s ease-out forwards;
  overflow: hidden;
  position: fixed;
  bottom: calc(70px - 100%);
  top: auto;
  left: 0;
  right: 0;
  width: 100vw;
  height: calc(100vh - 70px);
  z-index: 1000000;
  min-height: -webkit-fill-available;
  background: linear-gradient(
    135deg,
    var(--eb-blue) 0%,
    var(--eb-blue) 30%,
    var(--eb-blue-dark) 100%
  );
}

.styles_tray__1YGiT.styles_open__2owv8 {
  animation: styles_slideDownOpen__3H3Jp 0.3s ease-out forwards;
  bottom: 0;
  top: 70px;
}

tray.styles_animateClose__1ibS7 {
  animation: styles_slideDownClose__3JzHe 0.3s ease-out forwards;
}

.styles_bar__1DEHD {
  width: 100%;
  height: 70px;
  background-color: var(--eb-white);
  z-index: 1000;
  transition: all 0.3s ease-out;
}

.styles_bar__1DEHD.styles_open__2owv8 {
  height: 50px;
}

.styles_chevron__1Xxv8 {
  position: absolute;
  height: 35px;
  width: 35px;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease-out;
  transform: rotate(180deg);
}

.styles_chevron__1Xxv8 path {
  fill: var(--eb-blue);
}

.styles_chevron__1Xxv8.styles_open__2owv8 {
  transform: rotate(0deg);
}

.styles_content__3tENq {
  width: 100%;
  padding: 0 28px;
  flex-grow: 1;
}

@keyframes styles_slideDownOpen__3H3Jp {
  0% {
    bottom: calc(70px - 100%);
    top: auto;
  }
  100% {
    bottom: 0;
    top: 70px;
  }
}

@keyframes styles_slideDownClose__3JzHe {
  0% {
    bottom: 0;
    top: 70px;
  }
  100% {
    bottom: calc(70px - 100%);
    top: auto;
  }
}

.styles_small__1Dvt9 {
  font-size: 14px;
  padding: 0 10px;
}

.styles_medium__SjomB {
  font-size: 16px;
  padding: 5px 20px;
}

.styles_large__2dgk0 {
  font-size: 18px;
  padding: 10px 25px;
}

.styles_button__2W_a7 {
  color: #fff;
  transition: all 0.3s;
  position: relative;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.styles_button__2W_a7 span {
  transition: all 0.3s;
  line-height: 2;
  text-transform: uppercase;
  color: var(--eb-white);
}
.styles_button__2W_a7::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: var(--eb-white);
  border-bottom-color: var(--eb-white);
  transform: scale(0.1, 1);
}
.styles_button__2W_a7:hover span {
  letter-spacing: 2px;
}
.styles_button__2W_a7:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.styles_button__2W_a7::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.05);
}
.styles_button__2W_a7:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}

.styles_form__qMGGY {
  overflow: hidden;
  width: 100%;
  color: var(--eb-white);
}

.styles_formContent__RREyN {
  width: 100%;
  padding: 10px 0;
}

.styles_formFooter__2Azm0 {
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 10px;
}

.styles_title__1gZBd {
  width: 100%;
  justify-content: flex-start;
  padding: 20px 20px 0;
  color: var(--eb-white);
  font-weight: bold;
  font-size: 18px;
}

.styles_feedback__4LC3A {
  padding: 10px 20px 0;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  min-height: 30px;
  width: 100%;
}

.styles_error__19MK7 {
  color: var(--eb-tc);
}

.styles_success__2YigX {
  color: var(--eb-white);
}

.styles_input__16fLI {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.75);
  padding: 5px 0 5px 10px;
  flex: 0 0 49%;
  line-height: 2;
  outline: none;
  color: var(--eb-blue);
  font-size: 16px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.2s ease-in-out, border 0.1s ease-in-out;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.styles_input__16fLI:hover {
  border-color: var(--eb-white);
}

.styles_input__16fLI:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
  border-color: var(--eb-white);
}

.styles_row__3M4Wc {
  margin-bottom: 20px;
}

.styles_header__1tScf {
  position: fixed;
  z-index: 10000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  padding: 18px 0 0;
  height: 70px;
}

.styles_hamburgerMenu__P_FZB {
  position: absolute;
  left: 28px;
  bottom: 14px;
  height: 35px;
  width: 35px;
}

.styles_headerText__3mHyE {
  font-size: 36px;
}

.styles_navLinks__1-SUl {
  font-weight: bold;
  width: 100%;
  height: 80%;
}

.styles_navLink__3s6Xs {
  position: relative;
  font-size: 20px;
  color: var(--eb-sage);
  margin: 14px 0;
  line-height: 2;
  transition: all 0.2s ease-out;
  text-transform: uppercase;
}

.styles_navLink__3s6Xs.styles_active__1mBJA {
  opacity: 1;
  font-size: 22px;
  color: var(--eb-white);
}

.styles_navLink__3s6Xs::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 0;
  right: auto;
  height: 1px;
  width: 0px;
  background-color: var(--eb-sage);
  transition: all 0.2s ease-out;
}

.styles_navLink__3s6Xs.styles_active__1mBJA::after {
  width: 120px;
  background-color: var(--eb-white);
}

.styles_siteFooter__3pTik {
  color: var(--eb-white);
}

.styles_copyright__Eynnh {
  font-weight: bold;
}

.styles_adminPortal__1lXma {
  cursor: pointer;
  font-weight: bold;
  color: var(--eb-blue);
}

a {
  cursor: pointer !important;
  text-decoration: none;
  font-weight: bold;
}

.styles_footer__pD3wM.styles_closed__3sABC {
  height: 0px;
  padding: 0;
}

.styles_flyout__3jywf {
  animation: styles_slideLeftClose__5mmSw 0.2s ease-in-out forwards;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  width: 400px;
  z-index: 1000000;
  min-height: -webkit-fill-available;
  background: linear-gradient(
    135deg,
    var(--eb-blue) 0%,
    var(--eb-blue) 30%,
    var(--eb-blue-dark) 100%
  );
  box-shadow: 10px 10px 15px 0px var(--eb-blue);
}

.styles_flyout__3jywf.styles_open__2fiCE {
  animation: styles_slideLeftOpen__1rnbn 0.5s ease-in-out forwards;
  left: 0;
}

flyout.styles_animateClose__3xqaA {
  animation: styles_slideLeftClose__5mmSw 0.2s ease-in-out forwards;
}

.styles_flyout__3jywf.styles_mobile__1PwA- {
  width: 90%;
}

.styles_flyoutHeader__3xEfc {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  color: var(--eb-white);
  z-index: 1000;
}

.styles_nunito__eMFME {
  font-size: 28px;
  padding: 18px 15% 0;
  font-weight: bold;
}

.styles_canon__1I6Vt {
  font-size: 36px;
  padding-top: 18px;
}

.styles_closeIcon__InbB7 {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  height: 70px;
  width: 70px;
  padding: 18px 14px;
  cursor: pointer;
}

.styles_closeIcon__InbB7 path {
  fill: var(--eb-white);
}

.styles_flyoutContent__3uDaZ {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 112px 28px 42px;
  z-index: 100;
}

@keyframes styles_slideLeftOpen__1rnbn {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}

@keyframes styles_slideLeftClose__5mmSw {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

.styles_firebaseAuth__2GOWf {
  width: 100%;
  flex-grow: 2;
  padding: 20% 0;
}

.styles_firebaseAuth__2GOWf > div > div > form > ul {
  list-style: none;
  padding: 0;
}

.styles_firebaseAuth__2GOWf > div > div > form > ul > li {
  width: 100%;
}

.styles_firebaseAuth__2GOWf > div > div > form > ul > li > button {
  width: 100%;
  padding: 0;
  outline: none;
  border: 2px solid var(--eb-white);
  background-color: var(--eb-blue) !important;
  height: 140px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.styles_firebaseAuth__2GOWf > div > div > form > ul > li > button:hover {
  box-shadow: inset 0px 0px 25px 0px rgba(255, 255, 255, 0.1),
    0px 2px 10px -2px rgba(255, 255, 255, 0.5);
}

.styles_firebaseAuth__2GOWf > div > div > form > ul > li > button:hover::after {
  border: 2px solid var(--eb-white);
  width: calc(100% - 10px);
  height: calc(100% + 10px);
}

.styles_firebaseAuth__2GOWf > div > div > form > ul > li > button > span:first-child {
  height: 105px;
  background-color: var(--eb-white);
  border-radius: 50%;
  padding: 10px;
}

.styles_firebaseAuth__2GOWf > div > div > form > ul > li > button img {
  height: 100%;
}

.styles_firebaseAuth__2GOWf > div > div > form > ul > li > button > span:nth-child(2) {
  color: var(--eb-white);
  font-weight: bold;
  font-size: 18px;
  margin-left: 20px;
  font-family: 'Lato', sans-serif;
}

.styles_firebaseAuth__2GOWf > div > div > form > ul > li > button > span:last-child {
  display: none;
}

.styles_authActions__2z29r {
  height: 62px;
  width: 100%;
}

.styles_authAction__2ap_9 {
  margin: 10px 0;
  width: 80%;
}

.styles_authMessages__1Uzwl {
  width: 100%;
  height: 140px;
  font-size: 18px;
  font-weight: bold;
  color: var(--eb-white);
  border-bottom: 2px solid var(--eb-white);
  transition: all 0.2s ease-in-out;
  padding: 25% 0;
}

.styles_authMessages__1Uzwl.styles_error__3zen2 {
  color: var(--eb-tc);
  border-bottom: 2px solid var(--eb-tc);
}

.styles_modalOverlay__1tyht {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;
  background-color: rgba(255, 255, 255, 0.4);
}

.styles_modal__3xk__ {
  z-index: 10000;
  position: relative;
  box-shadow: 0 0 50px 10px var(--eb-blue);
  width: 50vw;
}

.styles_modal__3xk__::before {
  background-color: var(--eb-blue);
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
  content: ' ';
  z-index: 9999;
  border: 1px solid var(--eb-white);
}

.styles_modal__3xk__::after {
  background-color: var(--eb-blue);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  right: -10px;
  content: ' ';
  z-index: 9999;
  border: 1px solid var(--eb-white);
}

.styles_form__305Rc {
  overflow: hidden;
  width: 100%;
  color: var(--eb-white);
  background-color: var(--eb-blue);
  border: 1px solid var(--eb-white);
  z-index: 10001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
}

.styles_formContent__30RHS {
  padding: 20px;
  width: 100%;
}

.styles_formFooter__2jK8H {
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  padding: 15px 30px;
}

.styles_title__yrbiu {
  width: 100%;
  justify-content: flex-start;
  padding: 20px 20px 0;
  color: var(--eb-white);
  font-weight: bold;
  font-size: 18px;
}

.styles_feedback__3C_tJ {
  padding: 10px 20px 0;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
  min-height: 30px;
  width: 100%;
}

.styles_error__1C98P {
  color: var(--eb-tc);
}

.styles_success__we4pg {
  color: var(--eb-white);
}

.styles_input__L6l2F {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.75);
  padding: 5px 0 5px 10px;
  flex: 0 0 49%;
  line-height: 2;
  outline: none;
  color: var(--eb-blue);
  font-size: 16px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.2s ease-in-out, border 0.1s ease-in-out;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.styles_input__L6l2F:hover {
  border-color: var(--eb-white);
}

.styles_input__L6l2F:focus {
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
  border-color: var(--eb-white);
}

.styles_row__1c_Gm {
  margin-bottom: 20px;
}

.styles_footer__3mQTx {
  padding: 10px 20px 18px;
  height: 100px;
  width: 100%;
  transition: all 0.5s ease-in-out;
  font-size: 14px;
}

.styles_footer__3mQTx > span {
  margin-bottom: 10px;
}

.styles_copyright__1g6IF {
  font-weight: bold;
}

.styles_link__jhKhd {
  cursor: pointer;
  font-weight: bold;
  color: var(--eb-blue);
  margin: 0 10px;
}

a {
  cursor: pointer !important;
  text-decoration: none;
  font-weight: bold;
}

.styles_footer__3mQTx.styles_closed__nBL85 {
  height: 0px;
  padding: 0;
}

.styles_contact__F3_qq {
  position: fixed;
  z-index: 100000;
  right: 20px;
  bottom: 20px;
  left: auto;
  top: auto;
  -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
}

.styles_icon__377dz {
  -webkit-clip-path: circle(50% at 50% 50%);
          clip-path: circle(50% at 50% 50%);
  cursor: pointer;
  width: 50px;
  height: 50px;
  transition: all 0.2s ease-in-out;
}

.styles_icon__377dz > circle {
  fill: var(--eb-green);
}

.styles_icon__377dz > path {
  fill: var(--eb-white);
}

.styles_icon__377dz.styles_hovered__IRaW3 {
  transform: scale(1.1);
}

.styles_tooltip__3D7ld {
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 5px;
  width: 80px;
  font-size: 12px;
  position: absolute;
  bottom: calc(100% + 10px);
  right: -15px;
}

.styles_tooltip__3D7ld:after {
  content: ' ';
  position: absolute;
  top: 95%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--eb-green) transparent transparent transparent;
}

.styles_page__3FzxN {
  position: absolute;
  top: 0;
  bottom: auto;
  right: 0;
  left: 0;
}

.styles_content__2toUS {
  height: 100vh;
  width: 100vw;
  padding-top: 70px;
  position: relative;
  overflow-y: scroll;
}

.styles_grid__f1gbS {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
  height: calc(100vh - 70px);
  grid-template-areas: 'image bio';
  width: 100%;
  position: relative;
}

.styles_image__2tTuP {
  grid-area: image;
  position: relative;
  overflow: hidden;
}

.styles_image__2tTuP::before {
  content: ' ';
  position: absolute;
  z-index: 1;
  border: 1px solid var(--eb-blue);
  border-right: none;
  top: 2vw;
  bottom: 2vw;
  left: 2vw;
  right: 0;
}

.styles_image__2tTuP::after {
  content: ' ';
  position: absolute;
  z-index: 1;
  border: 1px solid var(--eb-blue);
  border-right: none;
  top: 1vw;
  bottom: 1vw;
  left: 3vw;
  right: 0;
}

.styles_img__1O_z9 {
  object-fit: cover;
  position: absolute;
  bottom: 2vw;
  right: 0;
  width: calc(100% - 3vw);
  max-height: calc(100% - 4vw);
}

.styles_bio__3YPq_ {
  grid-area: bio;
  background-color: var(--eb-blue);
  padding: 15% 4vw 25% 3vw;
  height: 100%;
  position: relative;
  box-shadow: 0 0 10px var(--eb-blue-dark);
}

.styles_bioMobile__2lZw6 {
  background-color: var(--eb-blue);
  padding: 3vh 5vw;
  height: calc(100vh - 140px);
  position: relative;
}

.styles_bioMobile__2lZw6::before {
  content: '';
  position: absolute;
  top: 3vw;
  bottom: 3vw;
  left: 0;
  right: 0;
  border: 1px solid var(--eb-white);
}

.styles_bioMobile__2lZw6::after {
  content: '';
  position: absolute;
  top: 1vw;
  bottom: 1vw;
  left: 3vw;
  right: 3vw;
  border: 1px solid var(--eb-white);
}

.styles_bioMobile__2lZw6 .styles_paragraph__KARjW {
  font-size: 14px;
}

.styles_bioMobile__2lZw6 .styles_heading__3oUsP {
  font-size: 16px;
}

.styles_bio__3YPq_::before {
  content: ' ';
  position: absolute;
  z-index: 1;
  border: 1px solid var(--eb-white);
  border-left: none;
  top: 2vw;
  bottom: 2vw;
  left: 0;
  right: 2vw;
}

.styles_bio__3YPq_::after {
  content: ' ';
  position: absolute;
  z-index: 1;
  border: 1px solid var(--eb-white);
  border-left: none;
  top: 1vw;
  bottom: 1vw;
  right: 3vw;
  left: 0;
}

.styles_paragraph__KARjW {
  color: var(--eb-white);
  font-weight: 500;
  line-height: 1.5;
  font-size: 18px;
  font-style: italic;
  letter-spacing: 0.4px;
  z-index: 100;
}

.styles_heading__3oUsP {
  font-size: 18px;
  color: var(--eb-white);
  line-height: 1.5;
}

.styles_link__LGkkn {
  text-decoration: none;
  font-weight: bold;
  color: var(--eb-sage);
}

.styles_carousel__M3lgC {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-height: 40vh;
}

.styles_testimonial__3BQfr {
  height: 36vh;
  padding: 2vh 2vw;
  margin: 2vh 4vw;
  line-height: 1.5;
  color: var(--eb-blue-dark);
  border: 1px solid var(--eb-blue-dark);
  font-size: 18px;
  position: relative;
  font-style: italic;
}

.styles_testimonial__3BQfr::before {
  position: absolute;
  content: '';
  top: 5px;
  bottom: 5px;
  left: -10px;
  right: -10px;
  border: inherit;
}

.styles_testimonial__3BQfr.styles_mobile__3rEUw {
  height: 38vh;
  margin: 5px 10px;
  padding: 10px 10px;
  font-size: 14px;
  line-height: 1.1;
}

.styles_testimonial__3BQfr.styles_mobile__3rEUw strong {
  font-size: 16px;
}

.styles_testimonial__3BQfr.styles_mobile__3rEUw .styles_author__Hcf0D {
  font-size: 16px;
}

.styles_paragraph__3Udrk {
  text-align: left;
  letter-spacing: 0.4px;
}

.styles_author__Hcf0D {
  align-self: flex-end;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
}

.styles_author__Hcf0D span {
  font-size: 16px;
}

.styles_strong__1hJHa {
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
}

.styles_frame__2NA3t {
  width: 100%;
  position: relative;
  background-color: var(--eb-blue);
}

@media only screen and (max-width: 1048px) {
  .styles_frame__2NA3t:last-of-type {
    margin-bottom: 70px;
  }
}

.styles_imageContainer__2e0M5 {
  width: 100%;
  position: relative;
}

.styles_imageContainer__2e0M5.styles_sixteenNine__3x-gJ {
  padding-bottom: 56.25%;
}

.styles_imageContainer__2e0M5.styles_fourThree__1smrW {
  padding-bottom: 75%;
}

.styles_imageContainer__2e0M5.styles_oneOne__257xW {
  padding-bottom: 100%;
}

.styles_image__KkSqK {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5) inset;
}

.styles_innerBorder__2x20b {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  border: 1px solid var(--eb-white);
}

.styles_caption__UZy29 {
  padding: 25px 0 35px;
  color: var(--eb-white);
  height: 50px;
}

.styles_descriptionOverlay__1YG4g {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 0;
  z-index: 99;
  background-color: transparent;
  -webkit-backdrop-filter: blur(0);
          backdrop-filter: blur(0);
  transition: all 0.4s ease-out;
}

.styles_descriptionOverlay__1YG4g.styles_visible__3ulo_ {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background-color: rgba(21, 30, 40, 0.5);
}

.styles_nothing__1HWnd {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(21, 30, 40, 0.8) 70%);
}

.styles_description__1DK3_ {
  width: 100%;
  font-size: 16px;
  font-style: italic;
  line-height: 1.75;
  font-weight: bold;
  padding: 10px 15px;
  color: var(--eb-white);
  z-index: 100;
  opacity: 0;
  transition: all 0.2s ease-out;
  text-align: center;
}

.styles_description__1DK3_.styles_mobile__1_APr {
  line-height: 1.5;
  font-size: 14px;
}

.styles_description__1DK3_.styles_visible__3ulo_ {
  opacity: 1;
  animation-delay: 0s;
}

.styles_outer__3g8Nm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative;
  width: 100%;
  height: 100%;
}

.styles_mobileOuter__Kggis {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
  width: 100%;
  height: 100%;
}

.styles_col__17FOU {
  width: 100%;
  height: 100%;
}

.styles_post__o8irJ {
  cursor: pointer;
  margin: 0 20px;
  border: 1px solid #d6d6d6;
  height: calc(50vh - 150px);
  padding: 10px;
  border-radius: 2px;
  background-color: #f9f9f9;
}

.styles_post__o8irJ:first-child {
  margin-left: 0;
}

.styles_post__o8irJ:last-child {
  margin-right: 0;
}

.styles_image__1a5xI {
  object-fit: contain;
  max-height: calc(50vh - 170px);
  min-height: calc(50vh - 170px);
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
}

.styles_instagramLinkContainer__1x8iD {
  grid-area: instagram-link;
  font-weight: bold;
}

.styles_instagramLink__3UJjY {
  cursor: pointer;
  padding: 5px 10px;
  position: relative;
  border-radius: 4px;
  background: linear-gradient(100deg, #f77737 0%, #e1306c 40%, #e1306c 60%, #5851db 100%);
}

.styles_profileName__zpzGh {
  line-height: 20px;
  margin-left: 10px;
  font-size: 16px;
  color: #f9f9f9;
}

.styles_icon__1CeR0 {
  color: #f9f9f9;
  font-size: 20px;
}

.styles_instagramFeed__ZK_8N {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-area: instagram;
  overflow-x: scroll;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
}

.styles_pinterestLinkContainer__2kZrj {
  justify-content: flex-end;
  grid-area: pinterest-link;
  font-weight: bold;
}

.styles_pinterestLink__yxN7k {
  cursor: pointer;
  padding: 5px 10px;
  position: relative;
  border-radius: 4px;
  background: #d42d2a;
}

.styles_profileName__3jkEL {
  line-height: 20px;
  margin-left: 10px;
  font-size: 16px;
  color: #f9f9f9;
}

.styles_icon__39NRG {
  color: #f9f9f9;
  font-size: 20px;
}

.styles_pinterestFeed__wK6Bs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-area: pinterest;
  overflow-x: scroll;
  width: 100%;
}

.styles_grid__IztmZ {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr 40px 1fr;
  grid-template-areas:
    'instagram-link'
    'instagram'
    'pinterest-link'
    'pinterest';
  height: calc(100vh - 140px);
  width: 100%;
  padding: 2%;
}

.styles_new__PzKgZ {
  position: fixed;
  z-index: 1000;
  right: 20px;
  bottom: 20px;
  left: auto;
  top: auto;
  -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  transition: all 0.2s ease-in-out;
  background-color: var(--eb-green);
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  color: var(--eb-white);
}

.styles_new__PzKgZ:hover {
  transform: scale(1.1);
}

.styles_new__PzKgZ > svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.styles_icon__2urdh {
  fill: var(--eb-white);
  padding: 20%;
}

.styles_tooltip__1MfnE {
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 5px;
  width: 80px;
  font-size: 12px;
  position: absolute;
  bottom: calc(100% + 8px);
  right: -15px;
  line-height: 1;
}

.styles_tooltip__1MfnE:after {
  content: ' ';
  position: absolute;
  top: 95%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--eb-green) transparent transparent transparent;
}

.styles_modalOverlay__1CIUe {
  position: absolute;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.styles_modal__3eptG {
  position: relative;
  height: 95%;
  width: 80%;
  background-color: var(--eb-white);
  border-radius: 4px;
  overflow: hidden;
}

.styles_modalHeader__1_3Hd {
  flex: 0 0 100px;
  color: var(--eb-green);
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
}

.styles_modalContent__2hb-f {
  position: relative;
  flex-grow: 1;
  width: 100%;
  padding: 0 14px 28px;
}

.styles_modalFooter__2uTp7 {
  width: 100%;
  flex: 0 0 100px;
  background-color: var(--eb-green);
  padding: 0 50px;
}

.styles_closeIcon__1Zu0d {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  border-radius: 50%;
}

.styles_closeIcon__1Zu0d svg {
  width: 100%;
  height: 100%;
}

.styles_closeIcon__1Zu0d path {
  fill: var(--eb-green);
}

.styles_selectContainer__X45C_ {
  height: 63px;
  width: 45%;
}

.styles_label__1p5rS {
  font-size: 14px;
  margin-bottom: 7px;
  color: var(--eb-green);
  font-weight: bold;
}

.styles_label__1p5rS::after {
  content: ':';
}

.styles_select__3wAco {
  font-size: 14px;
  outline: none;
  padding: 7px;
  width: 100%;
  border: 1px solid var(--eb-green);
  background-color: #fff;
  border-radius: 4px;
  color: var(--eb-green);
  font-weight: bold;
  cursor: pointer;
  height: 35px;
}

.styles_select__3wAco:hover {
  box-shadow: 0 0 5px -2px var(--eb-green);
}

.styles_inputContainer__9w713 {
  margin: 7px 0;
  height: 42px;
  width: 100%;
}

.styles_input__aRHoI {
  font-size: 14px;
  width: 100%;
  margin: 7px 0;
  color: var(--eb-green);
  outline: none;
  padding: 7px;
  border: 1px solid var(--eb-green);
  background-color: #fff;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.styles_input__aRHoI:hover,
.styles_input__aRHoI:focus {
  box-shadow: 0 0 5px -2px var(--eb-green);
}

.styles_textAreaContainer__3M4P7 {
  margin: 7px 0;
  min-height: 105px;
  width: 100%;
}

.styles_textArea__2-LmF {
  font-size: 14px;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  max-height: 210px;
  color: var(--eb-green);
  outline: none;
  padding: 7px;
  border: 1px solid var(--eb-green);
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.styles_textArea__2-LmF:hover,
.styles_textArea__2-LmF:focus {
  box-shadow: 0 0 5px -2px var(--eb-green);
}

.styles_inputContainer__Dk6FZ {
  width: 45%;
  height: 63px;
}

.styles_label__1La7e {
  font-size: 14px;
  margin-bottom: 7px;
  color: var(--eb-green);
  font-weight: bold;
}

.styles_label__1La7e::after {
  content: ':';
}

.styles_shared__3ZvDl {
  padding: 7px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  width: 100%;
  height: 35px;
}

.styles_shared__3ZvDl:hover {
  box-shadow: 0 0 5px -2px var(--eb-green);
}

.styles_button__1d5LD {
  border: 1px solid var(--eb-green);
  background-color: var(--eb-green);
  color: #fff;
}

.styles_fileName__12Kyu {
  padding: 7px;
  border: 1px solid var(--eb-green);
  background-color: #fff;
  color: var(--eb-green);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type='file'] {
  display: none;
}

.styles_projectForm__3SJxb {
  width: 80%;
}

.styles_projectPreview__3-FdO {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 500px;
  margin: 14px 0;
}

.styles_col__1ik6Y {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.styles_projectPreviewTitle__3mQee {
  margin-top: 14px;
  font-size: 36px;
  color: var(--eb-blue);
}

.styles_blankProject__YgbNX {
  font-size: 24px;
  font-weight: bold;
  color: var(--eb-blue);
  text-align: center;
  padding: 105px 0;
}

.styles_selectors__25VCV {
  width: 100%;
  margin: 7px 0;
}

.styles_pageContent__2HZla {
  display: grid;
  height: 100vh;
  padding: 0 42px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.styles_footerButton__F-ymg {
  margin: 0 35px;
  width: 200px;
}

.styles_userInfo__sFjhV {
  max-height: 140px;
  width: 100%;
  padding: 25% 14px;
  border-bottom: 1px solid var(--eb-white);
}

.styles_avatar__nnQNz {
  height: 105px;
  width: auto;
  border-radius: 50%;
  border: 2px solid var(--eb-white);
}

.styles_displayName___ykLW {
  line-height: 70px;
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
  color: var(--eb-white);
}

.styles_contentSelector__2oZKS {
  position: relative;
  width: 100%;
  padding: 20% 0;
  font-weight: bold;
  flex-grow: 2;
}

.styles_contentOption__1eKYR {
  width: 100%;
  position: relative;
  font-weight: bold;
  font-size: 18px;
  color: var(--eb-sage);
  cursor: pointer;
  margin: 15px 0;
  padding-left: 15px;
  transition: all 0.2s ease-out;
  line-height: 30px;
}

.styles_contentOption__1eKYR:not(.styles_active__2ieAr):hover {
  color: var(--eb-white);
  opacity: 0.8;
  font-size: 19px;
}

.styles_contentOption__1eKYR::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 15px;
  right: auto;
  height: 1px;
  width: 0px;
  background-color: var(--eb-sage);
  transition: all 0.2s ease-out;
}

.styles_contentOption__1eKYR.styles_active__2ieAr {
  font-size: 20px;
  color: var(--eb-white);
}

.styles_contentOption__1eKYR.styles_active__2ieAr::after {
  width: 60px;
  background-color: var(--eb-white);
}

.styles_authActions__2b3Bj {
  height: 104px;
  width: 100%;
}

.styles_authAction__1tVYd {
  margin: 10px 0;
  width: 80%;
}

.styles_page__nIHmV {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.styles_main__30DBT {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  padding-left: 400px;
}

.styles_contentContainer__3WFZo {
  position: relative;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

:root {
  --eb-white: #f2f7f5;
  --eb-green: #122b1e;
  --eb-green-light: #183928;
  --eb-green-dark: #0c1d14;
  --eb-sage: #afbe8f;
  --eb-tc: #e37463;
  --eb-blue: #151e28;
  --eb-blue-light: #1e2b39;
  --eb-blue-dark: #0c1117;
  --pad: 40px;
  --space: 0px;
  --stroke: 4px;
}

.canon {
  font-family: 'IM Fell French Canon SC', serif;
}

.lato {
  font-family: 'Lato', sans-serif;
}

.nunito {
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.arapey {
  font-family: 'Arapey', serif;
  font-weight: bold;
  font-style: italic;
}

.assistant {
  font-family: 'Assistant', sans-serif;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.bgWhite {
  background-color: var(--eb-white);
}

.bgWhiteTexture {
  background-color: var(--eb-white);
  background-image: url(/static/media/textile.68b3e753.png);
  background-repeat: repeat;
  background-size: 10%;
}

.bgSageTexture {
  background-color: var(--eb-sage);
  background-image: url(/static/media/textile.68b3e753.png);
  background-repeat: repeat;
  background-size: 10%;
}

.bgWhitePaper {
  background-color: var(--eb-white);
  background-image: url(/static/media/paper.6b3caa84.png);
  background-repeat: repeat;
  background-size: 20%;
}

.bgBluePaper {
  background-color: var(--eb-blue);
  background-image: url(/static/media/paper.6b3caa84.png);
  background-repeat: repeat;
  background-size: 5%;
}

.bgWhiteCityscape {
  background-image: url(/static/media/cityscape.748fe3fb.png);
}

.bgDarkGreen {
  background-color: var(--eb-green);
}

.bgLightGreen {
  background-color: var(--eb-sage);
}

.bgTerraCotta {
  background-color: var(--eb-tc);
}

.bgBlue {
  background-color: var(--eb-blue);
}

.textWhite {
  color: var(--eb-white);
}

.textDarkGreen {
  color: var(--eb-green);
}

.textLightGreen {
  color: var(--eb-sage);
}

.textTerraCotta {
  color: var(--eb-tc);
}

.textBlue {
  color: var(--eb-blue);
}

.elevation-0 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.14);
}

.elevation-0-inv {
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.14);
}

.elevation-1 {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
}

.elevation-2 {
  box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.12);
}

.elevation-2-inv {
  box-shadow: 0px -8px 12px -2px rgba(0, 0, 0, 0.12);
}

.elevation-3 {
  box-shadow: 0px 9px 20px -2px rgba(0, 0, 0, 0.15);
}

.elevation-4 {
  box-shadow: 0px 10px 28px -10px rgba(0, 0, 0, 0.5);
}

.frame-outer {
  box-sizing: content-box;
  width: 400px;
  background: var(--eb-blue);
  margin: 48px auto;
  padding: 16px;
  position: relative;
  overflow: hidden;
}

.frame-outer > img {
  box-sizing: content-box;
  width: 400px;
  height: auto;
  object-fit: cover;
  opacity: 0.5;
}

.frame-outer::after {
  box-sizing: content-box;
  content: ' ';
  display: block;
  position: absolute;
  top: var(--pad);
  right: var(--pad);
  left: var(--pad);
  bottom: var(--pad) - var(--space) + 7px;
  border: solid var(--stroke) var(--eb-white);
  border-bottom: 0;
}

.frame-inner {
  box-sizing: content-box;
  color: var(--eb-white);
  position: absolute;
  text-align: center;
  bottom: var(--pad) - var(--space);
  left: var(--pad);
  right: var(--pad);
  white-space: nowrap;
  overflow: hidden;
  font-family: 'IM Fell French Canon SC', serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.frame-inner > div {
  box-sizing: content-box;
  width: 200px;
  margin-left: calc(-1 * calc(2000px - calc(400px - var(--pad))) / 2);
}

.inner-text::before,
.inner-text::after {
  box-sizing: content-box;
  content: '';
  display: inline-block;
  width: 600px;
  height: var(--stroke);
  background: var(--eb-white);
  vertical-align: middle;
}

.inner-text::before {
  box-sizing: content-box;
  margin-right: 16px;
}

.inner-text::after {
  box-sizing: content-box;
  margin-left: 16px;
}

#root {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
  min-height: -webkit-fill-available;
}

