.new {
  composes: flex items-center justify-center from global;
  position: fixed;
  z-index: 1000;
  right: 20px;
  bottom: 20px;
  left: auto;
  top: auto;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  transition: all 0.2s ease-in-out;
  background-color: var(--eb-green);
  border-radius: 50%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  color: var(--eb-white);
}

.new:hover {
  transform: scale(1.1);
}

.new > svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.icon {
  fill: var(--eb-white);
  padding: 20%;
}

.tooltip {
  composes: lato flex items-center justify-center bgDarkGreen textWhite from global;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 5px;
  width: 80px;
  font-size: 12px;
  position: absolute;
  bottom: calc(100% + 8px);
  right: -15px;
  line-height: 1;
}

.tooltip:after {
  content: ' ';
  position: absolute;
  top: 95%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--eb-green) transparent transparent transparent;
}

.modalOverlay {
  composes: flex flex-column items-center justify-center from global;
  position: absolute;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal {
  composes: elevation-4 flex flex-column justify-start items-stretch from global;
  position: relative;
  height: 95%;
  width: 80%;
  background-color: var(--eb-white);
  border-radius: 4px;
  overflow: hidden;
}

.modalHeader {
  composes: lato flex items-center justify-center from global;
  flex: 0 0 100px;
  color: var(--eb-green);
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
}

.modalContent {
  composes: flex flex-column justify-center items-center from global;
  position: relative;
  flex-grow: 1;
  width: 100%;
  padding: 0 14px 28px;
}

.modalFooter {
  composes: flex items-center justify-center from global;
  width: 100%;
  flex: 0 0 100px;
  background-color: var(--eb-green);
  padding: 0 50px;
}

.closeIcon {
  position: absolute;
  right: 14px;
  top: 14px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  border-radius: 50%;
}

.closeIcon svg {
  width: 100%;
  height: 100%;
}

.closeIcon path {
  fill: var(--eb-green);
}
